import { ESpotContainerType, useESpotDataFromName } from '@/data/Content/_ESpotDataFromName';
import { EMAIL_PREF_IFRAME } from '@/data/constants/marketing';
import { ID } from '@/data/types/Basic';
import { processMarketingContent } from '@/utils/processMarketingContent';
import { renderContent } from '@/utils/renderContent';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { FC, useMemo } from 'react';

export const EmailPreferences: FC<{ id: ID }> = () => {
    const dataMap = (contents?: ESpotContainerType) =>
        contents?.MarketingSpotData?.at(0)?.baseMarketingSpotActivityData?.map(processMarketingContent);
    const { data: _data_email_pref_iframe } = useESpotDataFromName(EMAIL_PREF_IFRAME);
    const eSpotEmailPrefIframe = useMemo(() => dataMap(_data_email_pref_iframe), [_data_email_pref_iframe]);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box marginTop={isMobile ? 0 : '-1.0rem'} marginLeft={isMobile ? 0 : '-2.0rem'} marginBottom={isMobile ? 0 : '-9.0rem'}>
            {eSpotEmailPrefIframe?.map((content) => renderContent(content))}
        </Box>
    );
};
